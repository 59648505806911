<template>
  <div
    v-if="quizBtnShow"
    class="q-block relative"
    data-test="quiz-banner"
  >
    <img
      loading="lazy"
      class="q-block__img"
      :src="
        imgUrl(
          disabledMobile
            ? '/media/uploads/blog/quizBlock.png'
            : '/media/uploads/blog/quizBlockSmall.png',
          disabledMobile ? 1400 : 500,
          '',
        )
      "
      alt=""
    >
    <p class="q-block__title headline-2 relative">
      Автоматический подбор оптимального тарифа
    </p>
    <p class="q-block__subtitle title-2 relative">
      Наш искусственный интеллект найдет лучший тарифный план по указанным вами
      параметрам
    </p>
    <button
      class="q-block__btn title-4 white--text relative"
      @click="openModal"
    >
      Подобрать оптимальный тариф
    </button>
  </div>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'
import { imgUrl } from '~/helpers'

const ctx = useNuxtApp()
const mainStore = useMainStore()
const quizBtnShow = computed(() => mainStore.getQuizBtnShow)
const disabledMobile = computed(() => mainStore.disabledMobile)
const openModal = () => {
  ctx.$event('quizDialog', true)
}
</script>

<style scoped lang="scss">
.q-block {
  padding: 40px;
  border-radius: 16px;
  background: linear-gradient(
    115deg,
    #daecff 8.54%,
    #cecdff 56.73%,
    #f9d3ff 96.44%
  );
  overflow: hidden;
  @media (max-width: getBreakpoint(tablet)) {
    padding: 24px 24px 100px;
    border-radius: 12px;
  }
  @media (max-width: getBreakpoint(mobile-lg)) {
    padding: 24px 24px 92px;
  }
  @media (max-width: getBreakpoint(mobile-md)) {
    padding: 16px 16px 84px;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: right bottom;
    @media (max-width: getBreakpoint(tablet)) {
      object-position: center bottom;
    }
  }

  &__title {
    margin-bottom: 12px;
    max-width: 520px;
    @media (max-width: getBreakpoint(tablet)) {
      max-width: 400px;
      margin-bottom: 4px;
    }
  }
  &__subtitle {
    margin-bottom: 24px;
    max-width: 560px;
    @media (max-width: getBreakpoint(tablet)) {
      max-width: 460px;
      margin-bottom: 16px;
    }
  }

  &__btn {
    border-radius: 40px;
    padding: 16px 20px;
    background: linear-gradient(
      89deg,
      #ee3c6b 5.03%,
      #a949e7 42.39%,
      #5f60f5 67.38%,
      #5b73f1 76.39%,
      #5bbbe3 99.87%
    );
    box-shadow: 0px 4px 20px 0px rgba(34, 44, 80, 0.5);
    text-align: center;
    @media (max-width: getBreakpoint(tablet)) {
      padding: 8px;
      width: 100%;
      max-width: 400px;
      height: 44px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      height: 42px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 40px;
    }
  }
}
</style>
